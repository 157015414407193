import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainNavy,
  mainCyan,
  mainViolet,
  innerWidth,
  screen,
} from "../../components/common/variables";
import Nav from "../../components/nav";
import ProgressBar from "../../components/progress-bar";
import BottomNav from "../../components/bottom-nav";
import StyledButton from "../../components/styled-button";
import { motion } from "framer-motion";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 29,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainNavy};
  position: relative;
  color: ${mainWhite};
  overflow: auto;
  height: calc(100vh - 96px);
  padding: 0 0 50px 0;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    @media ${screen.xsmall} {
      padding: 140px 48px 0 48px;
    }
    @media ${screen.medium} {
      padding: 140px 0 0 0;
    }

    .texts {
      margin: 0 100px 0 0;
      max-width: 260px;
      width: 100%;

      h1 {
        font-size: 2.3rem;
        font-weight: 700;
        margin: 0 0 18px 0;
      }

      p {
        font-size: 1.12rem;
        margin: 0 0 45px 0;
      }

      ul {
        padding: 0 0 0 50px;
        li {
          list-style-type: disc;
          font-size: 1.12rem;
          margin: 0 0 14px 0;
        }
      }
    }

    .img-wrapper {
      max-width: calc(500px + 25vw);
      width: 100%;
      margin: -67px 0 0 0;

      .img {
        position: relative;

        .link {
          color: ${mainCyan};
          font-size: 1rem;
          font-weight: 700;
          display: block;
          position: absolute;

          &::after {
            content: "";
            position: absolute;
            height: 1px;
            background: ${mainCyan};
            top: 50%;

            transform: translateY(-50%);
          }

          &--one {
            top: 50px;
            right: -190px;
            &::after {
              width: 70px;
              left: -80px;
            }
          }

          &--two {
            top: 120px;
            right: -155px;
            &::after {
              width: 50px;
              left: -60px;
            }
          }

          @media ${screen.withCursor} {
            &:hover {
              color: ${mainViolet};
            }
          }
        }
      }
    }
  }
`;

const DiscoverTwo = ({ data }) => {
  const imgChart = data.imgChart.edges[0].node.childImageSharp.fluid;
  return (
    <Layout>
      <SEO title="Manage - 3-5" />
      <Nav />
      <Wrapper initial="close" animate={"open"} variants={variantsContainer}>
        <motion.div variants={variantsList}>
          <ProgressBar>
            <h2 className="title">MANAGE</h2>
            <div className="bars">
              <p className="selected">1. Test</p>
              <p className="selected selected">2. Test against</p>
              <p className="selected selected">3. Report</p>
            </div>
          </ProgressBar>
          <div className="inner-wrapper">
            <motion.div variants={variantsItem} className="texts">
              <h1>Results.​</h1>
              <p>
                This report shows application compatibility, performance, and
                functionality against a cumulative update(s).
              </p>
              <StyledButton to="/">START OVER</StyledButton>
            </motion.div>
            <motion.div variants={variantsItem} className="img-wrapper">
              <div>
                <Img fluid={imgChart} alt="result" />
              </div>
            </motion.div>
          </div>
        </motion.div>

        <BottomNav
          leftLabel={"BACK"}
          leftLink={"/manage/2-5/"}
          rightLabel={"BACK TO START"}
          rightLink={"/"}
        />
      </Wrapper>
    </Layout>
  );
};

export default DiscoverTwo;

export const dataQuery = graphql`
  {
    imgChart: allFile(
      filter: {
        relativePath: { eq: "rimo3-demo-report-cumulative-update.png" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
